<template>
  <footer class="relative z-10 w-full border-t red-bg border-zinc-800">
    <div class="relative z-10">
      <div class="w-full border-b border-zinc-800">
        <!-- <div
          class="flex items-center justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-4"
        >
          <ol class="flex items-center -mx-3">
            <li class="px-3" v-if="$GetColumn('company_facebook') != ''">
              <a
                :href="$GetColumn('company_facebook')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-secondary"
              >
                <i class="text-xl icon-facebook"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_instagram') != ''">
              <a
                :href="$GetColumn('company_instagram')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-secondary"
              >
                <i class="text-xl icon-instagram"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_line') != ''">
              <a
                :href="$GetColumn('company_line')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-secondary"
              >
                <i class="text-xl icon-line"></i>
              </a>
            </li>
          </ol>

          <button
            @click="ScrollToTop"
            class="flex items-center px-6 py-2 font-bold transition-all duration-300 bg-transparent border-2 border-secondary text-secondary md:hover:text-primary md:hover:bg-secondary"
          >
            回到頂部
          </button>
        </div> -->
      </div>
      <div class="w-full">
        <div
          class="flex flex-wrap items-center justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-14"
        >
          <div
            class="flex flex-wrap items-stretch order-2 w-full lg:order-1 md:w-auto"
          >
            <div class="w-1/2 mb-10 md:w-auto md:mr-20 sm:mb-0">
              <p class="mb-5 text-sm text-white">網站地圖</p>
              <div class="flex items-start">
                <ol class="mr-5">
                  <li
                    v-for="(item, item_index) in menu_list"
                    :key="`menu_${item_index}`"
                    class="mb-3"
                  >
                    <router-link
                      :to="item.link"
                      class="font-semibold text-white transition-all duration-300 hover:text-secondary"
                    >
                      {{ item.title }}
                    </router-link>
                  </li>
                </ol>
                <ol>
                  <li
                    v-for="(item, item_index) in second_menu_list"
                    :key="`menu_${item_index}`"
                    class="mb-3"
                  >
                    <router-link
                      :to="item.link"
                      class="font-semibold text-white transition-all duration-300 hover:text-secondary"
                    >
                      {{ item.title }}
                    </router-link>
                  </li>
                </ol>
              </div>
            </div>

            <div class="w-full mb-10 sm:w-1/2 md:w-auto sm:mb-0 sm:mr-20">
              <p class="mb-5 text-sm text-white">聯絡資訊</p>
              <ol>
                <li class="flex items-center mb-3">
                  <p class="mr-3 font-semibold text-white">聯絡電話</p>
                  <p class="font-semibold text-secondary font-garamond-pro">
                    {{ $GetColumn("company_phone") }}
                  </p>
                </li>
                <li class="flex items-center mb-3">
                  <p class="mr-3 font-semibold text-white">公司地址</p>
                  <p class="font-semibold text-secondary">
                    {{ $GetColumn("company_address") }}
                  </p>
                </li>
                <li class="flex items-center mb-3">
                  <p class="mr-3 font-semibold text-white">電子信箱</p>
                  <p class="font-semibold text-secondary font-garamond-pro">
                    {{ $GetColumn("company_email") }}
                  </p>
                </li>
                <li class="flex items-center mb-3">
                  <p class="mr-3 font-semibold text-white">營業時間</p>
                  <p class="font-semibold text-secondary font-garamond-pro">
                    {{ $GetColumn("company_time") }}
                  </p>
                </li>
              </ol>
            </div>

            <div class="w-full sm:w-1/2 md:w-auto md:mr-20">
              <p class="mb-5 text-sm text-white">社群平台</p>
              <ol>
                <li
                  v-if="$GetColumn('company_facebook') != ''"
                  class="flex items-center mb-3"
                >
                  <a
                    :href="$GetColumn('company_facebook')"
                    target="_blank"
                    class="text-lg font-semibold text-secondary font-garamond-pro"
                  >
                    Facebook
                  </a>
                </li>
                <li
                  v-if="$GetColumn('company_instagram') != ''"
                  class="flex items-center mb-3"
                >
                  <a
                    :href="$GetColumn('company_instagram')"
                    target="_blank"
                    class="text-lg font-semibold text-secondary"
                    >Instagram</a
                  >
                </li>
                <li
                  v-if="$GetColumn('company_line') != ''"
                  class="flex items-center mb-3"
                >
                  <a
                    :href="$GetColumn('company_line')"
                    target="_blank"
                    class="text-lg font-semibold text-secondary font-garamond-pro"
                  >
                    LINE@
                  </a>
                </li>
              </ol>
            </div>
          </div>

          <div
            class="flex flex-col-reverse flex-wrap items-center justify-between order-1 w-full sm:flex-nowrap sm:flex-row lg:flex-col lg:items-end mb-14 lg:mb-0 lg:justify-end lg:flex-1 lg:order-2 lg:w-auto"
          >
            <router-link to="/">
              <img
                :src="$ImageUrl($GetColumn('footer_logo'))"
                class="lg:mb-10 w-52 md:w-64"
              />
            </router-link>

            <div
              class="w-full mb-10 text-center sm:w-auto sm:mb-0 sm:text-left"
            >
              <button
                @click="ScrollToTop"
                class="px-5 py-2 font-bold transition-all duration-300 border-2 border-secondary text-secondary md:hover:text-primary md:hover:bg-secondary"
              >
                回到頂部
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full bg-black border-t border-zinc-800">
        <div
          class="flex items-center flex-wrap justify-center md:justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-4"
        >
          <p class="text-xs text-white">
            ©2023 {{ $GetColumn("company_name") }}，<router-link
              to="/terms/site_privacy"
              class="underline"
              >隱私權政策</router-link
            >
          </p>

          <p class="text-xs text-white">
            DESIGN BY
            <span class="ml-2 text-secondary">川錡網路科技有限公司</span>
          </p>
        </div>
      </div>
    </div>

    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 opacity-20 footer-bg"
    ></div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Video",
          link: "/video",
        },
        {
          title: "活動花絮",
          sub_title: "Gallery",
          link: "/gallery",
        },
        {
          title: "節日慶典",
          sub_title: "Festival",
          link: "/festival",
        },
      ],
      second_menu_list: [
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "交通資訊",
          sub_title: "Location",
          link: "/location",
        },
        {
          title: "隨喜參讚",
          sub_title: "Donation",
          link: "/donation",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
