<template>
  <section
    ref="MainContent"
    class="w-full px-5 mx-auto sm:mb-32 lg:mb-40 max-w-screen-2xl 2xl:px-0"
  >
    <header data-header-box class="relative w-full mb-10 text-center md:mb-20">
      <h2 data-header-title class="relative z-10 text-primary">
        <span class="inline-block text-4xl font-bold sm:text-5xl">組</span>
        <span class="inline-block text-4xl font-bold sm:text-5xl">織</span>
        <span class="inline-block text-4xl font-bold sm:text-5xl">架</span>
        <span class="inline-block text-4xl font-bold sm:text-5xl">構</span>
      </h2>

      <div
        data-header-subtitle
        class="absolute w-full transform -translate-x-1/2 bottom-4 left-1/2"
      >
        <div
          class="inline-block text-5xl italic font-bold opacity-60 md:text-7xl text-outlines"
        >
          <span class="inline-block font-garamond-pro">O</span>
          <span class="inline-block font-garamond-pro">R</span>
          <span class="inline-block font-garamond-pro">G</span>
          <span class="inline-block font-garamond-pro">A</span>
          <span class="inline-block font-garamond-pro">N</span>
          <span class="inline-block font-garamond-pro">I</span>
          <span class="inline-block font-garamond-pro">Z</span>
          <span class="inline-block font-garamond-pro">A</span>
          <span class="inline-block font-garamond-pro">T</span>
          <span class="inline-block font-garamond-pro">I</span>
          <span class="inline-block font-garamond-pro">O</span>
          <span class="inline-block font-garamond-pro">N</span>
        </div>
      </div>
    </header>

    <ol class="flex flex-wrap items-stretch justify-center">
      <li
        v-for="(group, group_index) in group_list"
        :key="`group_${group_index}`"
        class="w-1/2 px-5 mb-16 md:px-8 md:w-1/3 lg:w-1/5 lg:mb-0 group_card"
        data-section-card
      >
        <router-link :to="group.Link">
          <div class="relative w-full mb-5 aspect-square">
            <img
              :src="group.Image"
              class="relative z-10 object-cover w-full h-full rounded-full"
            />
            <span
              class="absolute top-0 bottom-0 left-0 right-0 z-0 block transform scale-110 rounded-full bg-opacity-20 bg-secondary"
            ></span>
            <span
              class="absolute top-0 bottom-0 left-0 right-0 z-0 block transition-all duration-300 transform rounded-full hover_bg bg-primary"
            ></span>
          </div>
          <p class="text-lg font-bold text-center text-primary">
            {{ group.Title }}
          </p>
        </router-link>
      </li>
    </ol>
  </section>
</template>

<script>
import { NewsSection } from "@/gsap/NewsSection.js";
export default {
  name: "OrganizationSection",
  data() {
    return {
      group_list: [
        {
          Title: "董事會",
          Image: "/img/carousel/20240222032556294.jpg",
          Link: "",
          Content: "",
        },
        {
          Title: "雷天會青年組",
          Image: "/img/carousel/20240222033311962.jpg",
          Link: "",
          Content: "",
        },
        {
          Title: "祭典組",
          Image: "/img/carousel/20240222033453841.jpg",
          Link: "",
          Content: "",
        },
        {
          Title: "慈善組",
          Image: "/img/carousel/20240222033640941.jpg",
          Link: "",
          Content: "",
        },
        {
          Title: "總務組",
          Image: "/img/carousel/20240222033842711.jpg",
          Link: "",
          Content: "",
        },
      ],
      gsapSetup: null,
    };
  },
  methods: {
    SetGsap() {
      this.gsapSetup.reset();
      this.gsapSetup.setup();
    },
  },
  mounted() {
    this.gsapSetup = new NewsSection(this.$refs.MainContent);
  },
};
</script>
