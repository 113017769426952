<template>
  <div class="relative w-full mb-14 md:mb-32">
    <div class="relative z-10 w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0">
      <div
        ref="CarouselBox"
        class="relative flex items-stretch justify-start w-full mb-5 overflow-hidden aspect-square md:aspect-video whitespace-nowrap"
      >
        <div
          data-carousel-item
          v-for="(carousel, carousel_index) in this.carousel_data"
          :key="`carousel_${carousel_index}`"
          class="absolute top-0 left-0 z-0 flex-shrink-0 w-full h-full"
        >
          <img
            :src="$ImageUrl(carousel.Image1)"
            class="hidden object-cover w-full h-full md:block"
          />
          <img
            :src="$ImageUrl(carousel.Image2)"
            class="block object-cover w-full h-full md:hidden"
          />
        </div>
      </div>
      <div class="w-full">
        <ol class="flex items-center justify-end -mx-2">
          <li
            v-for="(carousel, carousel_index) in this.carousel_data"
            :key="`bullet_${carousel_index}`"
            class="mx-2"
          >
            <span
              @click="ChangeCarousel(carousel_index)"
              class="inline-block w-2 h-2 transition-all duration-500 transform rotate-45 border cursor-pointer border-secondary md:hover:scale-110"
              :class="
                active_carousel_index == carousel_index
                  ? 'bg-secondary'
                  : 'bg-transparent'
              "
            ></span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "@/gsap/Carousel.js";

export default {
  name: "HomeCarousel",
  data() {
    return {
      carousel_gsap: null,
      carousel_timer: null,
      active_carousel_index: 0,
    };
  },
  methods: {
    ChangeCarousel(val = -1) {
      if (val == -1) {
        if (this.active_carousel_index == this.carousel_data.length - 1) {
          this.active_carousel_index = 0;
        } else {
          this.active_carousel_index += 1;
        }
      } else {
        this.active_carousel_index = val;
      }

      this.carousel_gsap.changeCarousel(this.active_carousel_index);

      this.carousel_timer != null ? clearInterval(this.carousel_timer) : "";
      this.carousel_timer = setInterval(() => {
        this.ChangeCarousel();
      }, 5000);
    },
  },
  computed: {
    carousel_data() {
      return this.$store.state.carousel_data;
    },
  },
  mounted() {
    this.carousel_gsap = new Carousel(this.$refs.CarouselBox);
    this.carousel_gsap.reset();
    this.carousel_gsap.changeCarousel(0);
  },
};
</script>
