<template>
  <div
    ref="MainContent"
    class="relative w-full pt-20 md:pt-24 2xl:pt-40 2xl:mb-40"
  >
    <div class="relative w-full mx-auto max-w-screen-2xl 2xl:px-0">
      <header
        data-header-box
        class="relative w-full px-5 mb-10 text-center md:mb-14 2xl:mb-20"
      >
        <h2 data-header-title class="relative z-10 text-primary">
          <span class="inline-block text-4xl font-bold sm:text-5xl">交</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">通</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">資</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">訊</span>
        </h2>

        <div
          data-header-subtitle
          class="absolute w-full transform -translate-x-1/2 bottom-4 left-1/2"
        >
          <div
            class="block text-6xl italic font-bold opacity-30 sm:inline-block sm:mr-5 md:text-7xl text-outlines"
          >
            <span class="inline-block font-garamond-pro">L</span>
            <span class="inline-block font-garamond-pro">O</span>
            <span class="inline-block font-garamond-pro">C</span>
            <span class="inline-block font-garamond-pro">A</span>
            <span class="inline-block font-garamond-pro">T</span>
            <span class="inline-block font-garamond-pro">I</span>
            <span class="inline-block font-garamond-pro">O</span>
            <span class="inline-block font-garamond-pro">N</span>
          </div>
        </div>
      </header>

      <div class="w-full aspect-square md:aspect-[3/1]">
        <iframe
          :src="$GetColumn('company_map')"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          class="w-full h-full"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { NewsSection } from "@/gsap/NewsSection.js";
export default {
  name: "HomeLocation",
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap = new NewsSection(this.$refs.MainContent);
    },
  },
};
</script>
