import gsap from "gsap";
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: "%",
    top: "%",
    rotation: "rad",
  },
});

export class MainMenu {
  constructor(el) {
    this.mainEl = el;
    this.mainBox = el.querySelector("[data-menu-box]");

    // this.menuBackground = el.querySelector("[data-menu-bg]");
    this.menuItems = el.querySelectorAll("[data-menu-item]");

    this.timeline = null;
  }

  reset() {
    gsap.set(this.mainEl, { y: "0%", x: "0%" });
    gsap.set(this.mainBox, { y: "-100%" });
    // gsap.set(this.menuBackground, { y: "-100%" });
    gsap.set(this.menuItems, { opacity: 0 });
  }

  setup() {}
  destroy() {}

  open() {
    this.timeline != null ? this.timeline.kill() : "";

    this.timeline = gsap.timeline();
    this.timeline.set(this.mainEl, { y: "0%", x: "100%" });

    this.timeline.fromTo(
      this.mainBox,
      {
        y: "-100%",
      },
      {
        y: "0%",
        delay: 0.1,
      },
      "menu-open"
    );
    // this.timeline.fromTo(
    //   this.menuBackground,
    //   {
    //     y: "-100%",
    //   },
    //   {
    //     y: "0%",
    //   },
    //   "menu-open"
    // );
    this.timeline.fromTo(
      this.menuItems,
      {
        y: "-20px",
        opacity: 0,
      },
      {
        y: "0px",
        opacity: 1,
        // stagger: 0.03,
        delay: 0.5,
        // ease: "elastic.inOut(1,0.3)",
        duration: 0.8,
      },
      "menu-open"
    );
  }
  close() {
    this.timeline != null ? this.timeline.kill() : "";

    this.timeline = gsap.timeline();

    this.timeline.fromTo(
      this.menuItems,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        // stagger: 0.03,
        // ease: "elastic.inOut(1,0.3)",
        duration: 0.8,
      },
      "menu-open"
    );

    this.timeline.fromTo(
      this.mainBox,
      {
        y: "0%",
      },
      {
        y: "-100%",
        delay: 0.6,
      },
      "menu-open"
    );
    // this.timeline.fromTo(
    //   this.menuBackground,
    //   {
    //     y: "0%",
    //   },
    //   {
    //     y: "-100%",
    //     delay: 0.2,
    //   },
    //   "menu-open"
    // );

    this.timeline.set(this.mainEl, { y: "0%", x: "0%" }, "menu-show");
  }
}
