<template>
  <div ref="MainContent" class="relative w-full">
    <div class="relative z-10 w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0">
      <header
        data-header-box
        class="relative w-full mb-10 text-center md:mb-20"
      >
        <h2 data-header-title class="relative z-10 text-primary">
          <span class="inline-block text-4xl font-bold sm:text-5xl">最</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">新</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">消</span>
          <span class="inline-block text-4xl font-bold sm:text-5xl">息</span>
        </h2>

        <div
          data-header-subtitle
          class="absolute w-full transform -translate-x-1/2 bottom-4 left-1/2"
        >
          <div
            class="inline-block text-5xl italic font-bold opacity-60 sm:mr-5 md:text-7xl text-outlines"
          >
            <span class="inline-block font-garamond-pro">N</span>
            <span class="inline-block font-garamond-pro">E</span>
            <span class="inline-block font-garamond-pro">W</span>
            <span class="inline-block font-garamond-pro">S</span>
          </div>
          <div
            class="inline-block text-5xl italic font-bold opacity-60 md:text-7xl font-garamond-pro text-outlines"
          >
            <span class="inline-block font-garamond-pro"> L</span>
            <span class="inline-block font-garamond-pro">I</span>
            <span class="inline-block font-garamond-pro">S</span>
            <span class="inline-block font-garamond-pro">T</span>
          </div>
        </div>
      </header>

      <ol class="flex flex-wrap items-stretch md:-mx-5">
        <li
          v-for="(news, newsIndex) in newsData"
          :key="`news${newsIndex}`"
          class="w-full pb-10 mb-10 border-b border-opacity-50 md:px-5 md:pb-0 md:w-1/3 md:mb-0 md:border-b-0 border-secondary"
          data-section-card
        >
          <NewsCard :news-data="news" />
        </li>
      </ol>
    </div>
    <!-- <div class="absolute right-0 z-0 hidden w-40 -bottom-44 md:block">
      <img
        data-scrub-image
        src="@/assets/img/cloud-small.png"
        class="block w-full opacity-10 md:opacity-30"
      />
    </div>
    <div class="absolute left-0 z-0 hidden w-52 -top-20 md:block">
      <img
        data-scrub-image
        src="@/assets/img/cloud-small.png"
        class="block w-full opacity-10 md:opacity-30"
      />
    </div> -->
  </div>
</template>

<script>
import NewsCard from "./NewsList/NewsCard.vue";
import { NewsSection } from "@/gsap/NewsSection.js";
export default {
  name: "NewsList",
  components: {
    NewsCard,
  },
  data() {
    return {
      linkHover: false,
      gsapSetup: null,
    };
  },
  methods: {
    SetGsap() {
      this.gsapSetup.reset();
      this.gsapSetup.setup();
    },
  },
  computed: {
    newsData() {
      return this.$store.state.news_data;
    },
  },
  mounted() {
    this.gsapSetup = new NewsSection(this.$refs.MainContent);
  },
};
</script>
