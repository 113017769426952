import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: "%",
    top: "%",
    rotation: "rad",
  },
});

export class AboutSection {
  constructor(el) {
    this.mainEl = el;
    this.parallaxBox = el.querySelector("[data-parallax-box]");
    this.image = this.parallaxBox.querySelector("img");
    this.content = el.querySelectorAll("[data-section-content]");
    this.header = el.querySelector("[data-header-box]");
    this.headerTitle = this.header.querySelectorAll("[data-header-title]");
    this.headerTitleEls = this.header.querySelectorAll(
      "[data-header-title] span"
    );
    this.headerSubTitle = this.header.querySelectorAll(
      "[data-header-subtitle]"
    );
    this.headerSubTitleEls = this.header.querySelectorAll(
      "[data-header-subtitle] span"
    );

    this.scrubTimeline = null;
    this.headerTimeline = null;
    window.addEventListener("resize", () => {
      this.resizeEvent();
    });
  }

  resizeEvent() {
    this.reset();
    this.setup();
  }

  destroy() {
    window.removeEventListener("resize", () => {
      this.resizeEvent();
    });
  }

  reset() {
    gsap.set(this.headerSubTitleEls, {
      y: "200px",
    });
    gsap.set(this.headerSubTitle, {
      opacity: 0,
    });
    gsap.set(this.headerTitleEls, {
      y: "100px",
    });
    gsap.set(this.headerTitle, {
      opacity: 0,
    });
    gsap.set(this.image, {
      opacity: 0,
      webkitFilter: "blur(10px)",
    });
  }

  setup() {
    this.setHeaderTimeline();
  }

  setHeaderTimeline() {
    this.headerTimeline != null ? this.headerTimeline.kill() : "";
    this.headerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.mainEl,
        start: "top 60%",
        toggleActions: "restart play play reverse",
      },
    });

    this.headerTimeline.fromTo(
      this.headerSubTitle,
      {
        opacity: 0,
      },
      {
        opacity: 0.5,
        duration: 2,
      },
      "sub-title-in"
    );
    this.headerTimeline.fromTo(
      this.headerSubTitleEls,
      {
        y: "200px",
      },
      {
        y: "0px",
        stagger: 0.05,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.headerTitle,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.2,
        duration: 1,
      },
      "sub-title-in"
    );
    this.headerTimeline.fromTo(
      this.headerTitleEls,
      {
        y: "100px",
      },
      {
        y: "0px",
        stagger: 0.05,
        delay: 0.2,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.content,
      {
        y: "30px",
        opacity: 0,
      },
      {
        y: "0px",
        opacity: 1,
        delay: 0.8,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.image,
      {
        y: "50px",
        opacity: 0,
        webkitFilter: "blur(10px)",
      },
      {
        y: "0px",
        opacity: 1,
        webkitFilter: "blur(0px)",
        delay: 0.4,
        duration: 0.5,
      },
      "sub-title-in"
    );
  }
}
