<template>
  <section class="relative z-10 w-full py-24 bg-black">
    <div class="w-full max-w-[1440px] mx-auto relative z-10 md:px-10 px-5">
      <header class="relative w-full mb-5">
        <h2 class="mb-1 text-3xl font-black text-white md:text-4xl">
          聯絡我們
        </h2>
        <p class="mb-3 font-bold text-secondary">CONTACT US</p>
      </header>

      <p class="mb-10 text-sm text-white">
        若您有任何疑問或需求，請留下您的資料與需求，
        我們會盡速與您聯絡，或直接來電與我們聯繫。
      </p>
      <div>
        <router-link
          to="/contact"
          class="inline-block px-10 py-2 text-sm font-bold text-black transition-all duration-300 bg-white border border-white hover:bg-black hover:text-white"
        >
          聯絡我們
        </router-link>
      </div>
    </div>

    <div class="absolute top-0 left-0 z-0 w-full h-full opacity-20">
      <img
        :src="$ImageUrl($GetColumn('contact_footer_image'))"
        class="object-cover w-full h-full"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
};
</script>
