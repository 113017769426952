import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: "%",
    top: "%",
    rotation: "rad",
  },
});

export class NewsSection {
  constructor(el) {
    this.mainEl = el;
    this.background = el.querySelector("[data-main-background]");
    this.cards = el.querySelectorAll("[data-section-card]");
    this.cardDateEls = el.querySelectorAll(
      "[data-section-card] [data-card-date]"
    );
    this.header = el.querySelector("[data-header-box]");
    this.headerTitle = this.header.querySelectorAll("[data-header-title]");
    this.headerTitleEls = this.header.querySelectorAll(
      "[data-header-title] span"
    );
    this.headerSubTitle = this.header.querySelectorAll(
      "[data-header-subtitle]"
    );
    this.headerSubTitleEls = this.header.querySelectorAll(
      "[data-header-subtitle] span"
    );
    this.scrubImages = el.querySelectorAll("[data-scrub-image]");

    this.scrubTimeline = null;
    this.headerTimeline = null;
    this.cardTimeline = [];
    window.addEventListener("resize", () => {
      this.resizeEvent();
    });
  }

  resizeEvent() {
    this.reset();
    this.setup();
  }

  destroy() {
    window.removeEventListener("resize", () => {
      this.resizeEvent();
    });
  }

  reset() {
    this.cardTimeline.forEach((timeline) => {
      timeline.kill();
    });
    this.cardTimeline = [];
    gsap.set(this.scrubImages, {
      x: "0%",
    });
    gsap.set(this.cards, {
      y: "10%",
    });
    gsap.set(this.headerSubTitleEls, {
      y: "200px",
    });
    gsap.set(this.headerSubTitle, {
      opacity: 0,
    });
    gsap.set(this.headerTitleEls, {
      y: "100px",
    });
    gsap.set(this.headerTitle, {
      opacity: 0,
    });
  }

  setup() {
    this.setScrubTimeline();
    this.setHeaderTimeline();
  }

  setHeaderTimeline() {
    this.headerTimeline != null ? this.headerTimeline.kill() : "";
    this.headerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.mainEl,
        start: "top 70%",
        toggleActions: "restart play play reverse",
      },
    });

    this.headerTimeline.fromTo(
      this.headerSubTitle,
      {
        opacity: 0,
      },
      {
        opacity: 0.5,
        duration: 2,
      },
      "sub-title-in"
    );
    this.headerTimeline.fromTo(
      this.headerSubTitleEls,
      {
        y: "200px",
      },
      {
        y: "0px",
        stagger: 0.05,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.headerTitle,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.2,
        duration: 1,
      },
      "sub-title-in"
    );
    this.headerTimeline.fromTo(
      this.headerTitleEls,
      {
        y: "100px",
      },
      {
        y: "0px",
        stagger: 0.05,
        delay: 0.2,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.cards.forEach((card) => {
      let card_timeline = gsap.timeline({
        scrollTrigger: {
          trigger: card,
          start: "top 70%",
          toggleActions: "restart play play reverse",
        },
      });
      card_timeline.fromTo(
        card,
        {
          y: "50px",
          opacity: 0,
        },
        {
          y: "0px",
          opacity: 1,
          delay: 0.5,
          duration: 0.5,
        }
      );
      this.cardTimeline.push(card_timeline);
    });
  }

  setScrubTimeline() {
    this.scrubTimeline != null ? this.scrubTimeline.kill() : "";
    this.scrubTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.mainEl,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    this.scrubTimeline.fromTo(
      this.scrubImages[0],
      {
        x: "50%",
      },
      {
        x: "-50%",
      },
      "same"
    );
    this.scrubTimeline.fromTo(
      this.scrubImages[1],
      {
        x: "-50%",
      },
      {
        x: "50%",
      },
      "same"
    );
  }
}
