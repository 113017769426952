<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full overflow-hidden mb-28 md:mb-40 lg:py-56 red-bg"
  >
    <div class="w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0">
      <div
        class="relative z-10 flex flex-wrap items-stretch w-full pb-20 lg:pb-0"
      >
        <div class="w-full pt-28 md:pt-80 lg:pt-0">
          <header data-header-box class="relative mb-14 sm:mb-20 lg:mb-0">
            <h2
              data-header-title
              class="relative z-10 text-center lg:text-left text-secondary"
            >
              <span class="inline-block text-4xl font-bold sm:text-5xl"
                >神</span
              >
              <span class="inline-block text-4xl font-bold sm:text-5xl"
                >尊</span
              >
              <span class="inline-block text-4xl font-bold sm:text-5xl"
                >介</span
              >
              <span class="inline-block text-4xl font-bold sm:text-5xl"
                >紹</span
              >
            </h2>
            <div
              data-header-subtitle
              class="absolute z-0 w-full text-center transform -translate-x-1/2 bottom-5 lg:w-auto lg:text-left left-1/2 lg:translate-x-0 lg:left-44 lg:bottom-auto lg:-top-14"
            >
              <p
                class="inline-block text-5xl italic font-bold md:block sm:text-7xl font-garamond-pro text-outlines text-secondary"
              >
                <span class="inline-block opacity-30 font-garamond-pro">G</span>
                <span class="inline-block opacity-30 font-garamond-pro">O</span>
                <span class="inline-block opacity-30 font-garamond-pro">D</span>
              </p>
              <p
                class="inline-block text-5xl italic font-bold md:block sm:text-7xl font-garamond-pro text-outlines text-secondary"
              >
                <span class="inline-block opacity-30 font-garamond-pro">I</span>
                <span class="inline-block opacity-30 font-garamond-pro">N</span>
                <span class="inline-block opacity-30 font-garamond-pro">T</span>
                <span class="inline-block opacity-30 font-garamond-pro">R</span>
                <span class="inline-block opacity-30 font-garamond-pro">O</span>
              </p>
            </div>
          </header>
        </div>
        <div
          data-section-content
          class="relative z-10 order-2 w-full overflow-hidden lg:order-1 lg:w-1/3 md:pr-10"
        >
          <div
            class="relative flex items-start w-full overflow-hidden lg:block lg:h-full"
          >
            <div
              data-content-card
              v-for="(god, godIndex) in brand_data"
              :key="`godContent${godIndex}`"
              class="top-0 left-0 flex items-center justify-start flex-shrink-0 w-full transform lg:h-full lg:absolute -translate-y-0"
            >
              <div class="w-full">
                <h4 class="mb-5 text-2xl font-semibold text-secondary">
                  {{ god.ListTitle }}
                </h4>
                <div class="mb-10 md:mb-20 text-zinc-300">
                  <p>
                    {{ god.Description }}
                  </p>
                </div>
                <div class="flex justify-end">
                  <RouterLink
                    @mouseenter.native="hover_index = godIndex"
                    @mouseleave.native="hover_index = -1"
                    :to="god.Link"
                    class="flex items-center font-bold transition-all duration-500 w-52 text-secondary"
                    :class="
                      hover_index == godIndex
                        ? ' text-primary'
                        : ' text-secondary'
                    "
                  >
                    <i
                      class="flex-1 block h-[1px] origin-right transform transition-transform duration-500 bg-current mr-3"
                      :class="
                        hover_index == godIndex ? 'scale-x-75 ' : 'scale-x-100 '
                      "
                    ></i>
                    了解更多
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-image-box
          class="relative z-0 order-1 w-full mb-5 lg:mb-0 lg:order-2 lg:w-2/3"
        >
          <div
            class="absolute top-0 bottom-0 right-0 z-20 hidden w-full pointer-events-none lg:block"
          >
            <button
              @click="ChangeCarousel('prev')"
              class="absolute left-0 z-10 items-center justify-center hidden w-12 h-12 transition-all duration-300 transform -translate-x-5 -translate-y-1/2 border-2 pointer-events-auto bg-primary focus:outline-none hover:bg-secondary hover:text-primary text-secondary border-secondary lg:flex top-1/2"
            >
              <span class="text-xl icon-chevron-left"> </span>
            </button>
            <button
              @click="ChangeCarousel('next')"
              class="absolute transition-all pointer-events-auto items-center justify-center duration-300 hidden right-[20%] translate-x-4 z-10 w-12 h-12 transform -translate-y-1/2 border-2 bg-primary focus:outline-none hover:bg-secondary hover:text-primary text-secondary border-secondary lg:flex top-1/2"
            >
              <span class="text-xl icon-chevron-right"> </span>
            </button>
          </div>

          <div class="relative z-0 flex items-stretch -mx-5">
            <div
              v-for="(god, godIndex) in brand_data"
              data-image-card
              :key="`godImage${godIndex}`"
              class="lg:w-2/5 w-full relative px-5 aspect-square lg:aspect-[4/5] overflow-hidden flex-shrink-0"
            >
              <div
                data-image-cover
                class="absolute top-0 bottom-0 z-10 bg-black bg-opacity-80 left-5 right-5"
              ></div>
              <img
                :src="$ImageUrl(god.Image1)"
                class="relative z-0 object-cover w-full h-[150%]"
              />
            </div>
          </div>
        </div>

        <div
          class="flex items-center justify-start order-3 w-full pt-10 lg:hidden"
        >
          <button
            @click="ChangeCarousel('prev')"
            class="flex items-center justify-center w-12 h-12 transition-all duration-300 border-2 bg-primary hover:bg-secondary hover:text-primary text-secondary border-secondary focus:outline-none"
          >
            <span class="text-xl icon-chevron-left"> </span>
          </button>
          <button
            @click="ChangeCarousel('next')"
            class="z-10 flex items-center justify-center w-12 h-12 transition-all duration-300 translate-x-4 border-2 bg-primary hover:bg-secondary hover:text-primary text-secondary border-secondary focus:outline-none"
          >
            <span class="text-xl icon-chevron-right"> </span>
          </button>
        </div>
      </div>

      <div class="absolute right-0 z-0 hidden w-64 md:block top-32">
        <img
          data-scrub-image
          src="@/assets/img/white_cloud.png"
          class="block w-full opacity-30 md:opacity-10"
        />
      </div>
      <div class="absolute left-0 z-0 hidden w-40 md:block bottom-32">
        <img
          data-scrub-image
          src="@/assets/img/white_cloud_1.png"
          class="block w-full opacity-30 md:opacity-10"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { GodSection } from "@/gsap/GodSection.js";

export default {
  name: "GodsList",
  data() {
    return {
      godList: [
        {
          title: "巡天元帥",
          content: "「巡天元帥」又稱陳元帥，每逢農曆二月十五日為聖誕節日。",
          image:
            "/img/gods/15965200_1175845115818128_2361102253767339033_n.jpg",
        },
        {
          title: "濟公活佛",
          content: "酒肉穿腸過，佛祖心中留；世人若學我，如同進魔道。",
          image: "/img/gods/20231215202651515.jpg",
        },
        {
          title: "文武尊王",
          content:
            "文武尊王 尊稱為雙忠公。一般來說，指的是唐朝肅宗至德二載（757年）時，死守睢陽而身殉安史之亂的張巡、許遠，這兩位忠臣被奉為神明。",
          image:
            "/img/gods/15965200_1175845115818128_2361102253767339033_n.jpg",
        },
      ],
      hover_index: -1,
      section_gsap: null,
    };
  },
  methods: {
    ChangeCarousel(action) {
      this.section_gsap.changeCarousel(action);
    },
    SetGsap() {
      this.section_gsap = new GodSection(this.$refs.MainContent);
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  computed: {
    brand_data() {
      return this.$store.state.brand_data;
    },
  },
};
</script>
