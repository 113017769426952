<template>
  <div id="app" class="relative z-10 flex flex-col w-full min-h-screen main-bg">
    <MainLoading />

    <ShopCartDrawer />
    <SearchDialog />

    <template v-if="all_data_loaded">
      <MainHeader
        :menu_status="menu_status"
        @menu-action="menu_status = !menu_status"
      />
      <MainMenu
        ref="MainMenu"
        :menu_status="menu_status"
        @menu-action="menu_status = false"
      />
      <MainDialog />
      <!-- Cookie與廣告資料搜集同意視窗 -->
      <CookieConsentDialog />

      <!-- 114 77 -->
      <div
        class="relative z-10 flex-1"
        :class="announcement_list.length > 0 ? 'pt-[142px]' : 'pt-[120px]'"
      >
        <router-view
          class="relative z-10"
          :window_size="window_size"
          @set-meta="SetMetaData"
          @load-image="LoadImage"
        />
      </div>
      <ContactFooter />
      <MainFooter />
    </template>

    <div
      class="fixed top-0 left-0 z-0 flex items-center justify-center w-full h-full transform"
    >
      <img
        src="@/assets/img/background_1.png"
        class="max-w-full w-[680px] block mx-auto opacity-10 md:opacity-10"
      />

      <div
        class="absolute right-0 z-0 hidden w-52 lg:w-64 top-1/3 move_cloud right md:block"
      >
        <img
          src="@/assets/img/gold_cloud.png"
          class="block w-full opacity-30"
        />
      </div>
      <div
        class="absolute left-0 z-0 hidden w-40 top-3/4 move_cloud left md:block"
      >
        <img
          src="@/assets/img/gold_cloud_1.png"
          class="block w-full opacity-30"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ImageLoader } from "@/gsap/ImageLoader";
import { GetMetaData } from "@/common/meta";
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainMenu from "@/components/MainMenu.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import ShopCartDrawer from "@/components/ShopCartDrawer.vue";
import SearchDialog from "@/components/SearchDialog.vue";
import CookieConsentDialog from "@/components/CookieConsentDialog.vue";
export default {
  name: "App",
  components: {
    MainDialog,
    MainLoading,
    MainHeader,
    MainFooter,
    MainMenu,
    ContactFooter,
    ShopCartDrawer,
    SearchDialog,
    CookieConsentDialog,
  },
  data() {
    return {
      image_loader: null,
      menu_status: false,
      meta_data: null,
      window_size: window.innerWidth,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    SetMetaData(title, content, image) {
      this.meta_data = GetMetaData(title, content, image);
    },
  },
  watch: {
    all_data_loaded() {
      if (this.all_data_loaded) {
        this.$store.dispatch("shopcart_module/GetShopCart");
        this.$store.commit("SetLoading", -1);
      }
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  mounted() {
    this.$store.commit("SetLoading", 1);
    this.image_loader = new ImageLoader();
    window.addEventListener("resize", () => {
      this.menu_status = false;
      this.window_size = window.innerWidth;
    });
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch("getAllData") : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style src="@/assets/css/output.css"></style>
