<template>
  <main class="relative w-full overflow-hidden">
    <Carousel />
    <About ref="About" />
    <OrganizationSection ref="OrganizationSection" />
    <GodsList ref="GodsList" />
    <NewsList ref="NewsList" />
    <HomeLocation ref="HomeLocation" />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import About from "@/components/home/About.vue";
import NewsList from "@/components/home/NewsList.vue";
import GodsList from "@/components/home/GodsList.vue";
import HomeLocation from "@/components/home/Location.vue";
import OrganizationSection from "@/components/home/Organization.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    About,
    NewsList,
    GodsList,
    HomeLocation,
    OrganizationSection,
  },
  methods: {
    SetGsap() {
      this.$refs.NewsList.SetGsap();
      this.$refs.About.SetGsap();
      this.$refs.GodsList.SetGsap();
      this.$refs.HomeLocation.SetGsap();
      this.$refs.OrganizationSection.SetGsap();
    },
  },
  computed: {
    image_loaded() {
      return this.$store.state.image_loaded;
    },
  },
  watch: {
    image_loaded() {
      if (this.image_loaded) {
        this.SetGsap();
      }
    },
  },
  created() {
    const meta_data = this.$store.getters.page_meta_data("首頁");
    this.$PageInit(
      this,
      meta_data.Title,
      meta_data.Content,
      meta_data.Image == "" ? "" : this.$ImageUrl(meta_data.Image)
    );
  },
};
</script>
