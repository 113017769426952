<template>
  <router-link
    @mouseenter.native="cardHover = true"
    @mouseleave.native="cardHover = false"
    :to="`/news/${newsData.NewsID}`"
  >
    <article class="flex flex-col w-full h-full">
      <p
        data-card-date
        class="italic font-bold text-center opacity-50 text-7xl md:text-6xl md:text-left text-secondary font-garamond-pro text-outlines"
      >
        {{ dateText }}
      </p>
      <div class="block w-full mb-5 overflow-hidden aspect-video">
        <img
          :src="$ImageUrl(newsData.Image1)"
          class="object-cover w-full h-full transition-all transform"
          :class="
            cardHover
              ? 'md:scale-110 md:duration-1000 md:ease-in-out'
              : 'md:scale-100 md:duration-1000'
          "
        />
      </div>

      <header class="flex flex-col flex-1 text-center md:text-left">
        <h3
          class="mb-2 text-lg font-bold transition-all duration-500 news-card-title lg:text-lg"
          :class="cardHover ? 'md:text-secondary text-primary' : 'text-primary'"
        >
          {{ newsData.Title }}
        </h3>
        <p
          class="text-sm transition-all duration-500 sm:text-base"
          :class="
            cardHover ? 'md:text-zinc-400 text-zinc-500' : 'text-zinc-500'
          "
        >
          {{ GetContent(newsData.Content) }}
        </p>
      </header>
    </article>
  </router-link>
</template>

<script>
export default {
  props: {
    newsData: {
      type: Object,
    },
  },
  data() {
    return {
      cardHover: false,
    };
  },
  methods: {
    GetContent(val) {
      var msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp
      msg = msg.replace(/&nbsp;/gi, ""); //去掉npsp

      return msg.slice(0, 60) + "...";
    },
  },
  computed: {
    dateText() {
      return this.newsData.created_at.slice(5, 10).replace("-", "");
    },
  },
};
</script>
