import { gsap } from "@/gsap/GsapLoader";

export class HeaderDropDown {
  constructor(el, index) {
    this.parent = document.querySelector(
      "[data-header-dropdown='" + index + "']"
    );
    this.el = el;
    this.topPos = this.parent.getBoundingClientRect().bottom;
    this.leftPos =
      this.parent.getBoundingClientRect().right -
      this.el.getBoundingClientRect().width;

    this.timeline = null;
  }

  reset() {
    this.timeline != null ? this.timeline.kill() : "";
    this.topPos = this.parent.getBoundingClientRect().bottom;
    this.leftPos =
      this.parent.getBoundingClientRect().right -
      this.el.getBoundingClientRect().width;
    gsap.set(this.el, {
      left: this.leftPos + "px",
      top: this.topPos + "px",
      pointerEvents: "none",
      opacity: 0,
      y: "-10%",
    });
  }

  open() {
    this.reset();
    this.timeline = gsap.timeline();

    this.timeline
      .set(this.el, {
        pointerEvents: "auto",
      })
      .to(this.el, {
        opacity: 1,
        y: "0%",
        duration: 0.3,
      });
  }
  close() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    this.timeline.to(this.el, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        gsap.set(this.el, {
          pointerEvents: "none",
        });
      },
    });
    this.timeline.set(this.el, {
      y: "-10%",
    });
  }
}
