<template>
  <div ref="MainContent" class="fixed top-0 bottom-0 z-30 w-full -left-full">
    <div data-menu-box class="relative z-10 w-full h-full red-bg">
      <div class="w-full h-full overflow-hidden">
        <div class="h-full max-h-full px-10 overflow-y-auto pt-36">
          <ol class="w-full mb-10">
            <li
              data-menu-item
              v-for="(item, itemIndex) in menuList"
              :key="`menu_item_${itemIndex}`"
              class="w-full mb-6"
            >
              <RouterLink
                v-if="item.type != 'dropdown'"
                @click.native="$emit('menu-action')"
                :to="item.link"
                class="block w-full pb-3 text-white transition-all duration-500 border-b border-white sm:pb-6 hover:border-secondary hover:text-secondary"
              >
                <span class="block font-bold sm:text-xl">{{ item.title }}</span>
              </RouterLink>
              <div
                v-else
                class="border-b"
                :class="
                  dropDownOpenIndex === itemIndex
                    ? 'border-white'
                    : 'border-transparent'
                "
              >
                <button
                  @click="OpenDropDown(itemIndex)"
                  class="flex items-center justify-between w-full pb-3 text-left text-white transition-all duration-500 border-b sm:pb-6 hover:border-secondary hover:text-secondary"
                >
                  <span class="block font-bold sm:text-xl">{{
                    item.title
                  }}</span>
                  <span
                    class="transition-all duration-300 transform material-symbols-outlined icon-chevron-down"
                    :class="dropDownOpenIndex == itemIndex ? '' : '-rotate-90'"
                  >
                  </span>
                </button>
                <div
                  data-dropdown-box
                  class="overflow-hidden transition-all duration-300"
                >
                  <div>
                    <RouterLink
                      @click.native="$emit('menu-action')"
                      v-for="(subItem, subItemIndex) in item.list"
                      :key="`subItem_${itemIndex}_${subItemIndex}`"
                      :to="subItem.link"
                      class="block w-full py-4 pl-10 text-white transition-all duration-500 border-b border-white border-opacity-50 hover:border-secondary hover:text-secondary"
                    >
                      <span class="block font-bold sm:text-xl">{{
                        subItem.title
                      }}</span>
                    </RouterLink>
                  </div>
                </div>
              </div>
            </li>
          </ol>
          <div data-menu-item class="w-full mb-10 text-white">
            <p class="mb-2 text-sm font-bold">
              {{ $GetColumn("company_phone") }}
            </p>
            <p class="mb-5 text-sm font-bold">
              {{ $GetColumn("company_address") }}
            </p>
            <!-- <a class="text-sm font-bold text-primary-light">Google Map</a> -->
          </div>
          <div data-menu-item class="w-full mb-10 text-white">
            <div>
              <a
                :href="$GetColumn('company_facebook')"
                target="_blank"
                class="mb-2 text-xl font-bold transition-colors duration-500 text-secondary hover:text-primary-light"
                >Facebook</a
              >
            </div>
            <div>
              <a
                :href="$GetColumn('company_instagram')"
                target="_blank"
                class="block text-xl font-bold transition-colors duration-500 text-secondary hover:text-primary-light"
                >Instagram</a
              >
            </div>
          </div>
          <div data-menu-item class="w-full mb-10 text-white">
            <p class="mb-2 text-sm font-bold">
              © {{ new Date().getFullYear() }} {{ $GetColumn("company_name") }}
            </p>
          </div>
        </div>
      </div>
      <!-- <div
        class="absolute bottom-0 left-0 right-0 h-20 transform translate-y-full bg-primary rounded-b-3xl"
      ></div> -->
    </div>
    <!-- <div
      data-menu-bg
      class="absolute top-0 bottom-0 left-0 right-0 z-0 w-full transform bg-primary-light"
    > -->
    <!-- <div
        class="absolute bottom-0 left-0 right-0 h-20 transform translate-y-full bg-primary-light rounded-b-3xl"
      ></div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { MainMenu } from "@/gsap/MainMenu.js";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropDownOpenIndex: -1,
      menuList: [
        {
          title: "關於我們",
          link: "/about",
          type: "dropdown",
          list: [
            {
              title: "雷天帝王之沿革",
              link: "/about/history",
              type: "link",
            },
            {
              title: "神尊介紹",
              link: "/about/god",
              type: "link",
            },
            {
              title: "關於協會",
              link: "/about/association",
              type: "link",
            },
          ],
        },
        {
          title: "最新消息",
          link: "/news",
          type: "list_item",
        },
        {
          title: "影音專區",
          link: "/video",
          type: "list_item",
        },
        {
          title: "活動花絮",
          link: "/gallery",
          type: "list_item",
        },
        {
          title: "交通資訊",
          link: "/location",
          type: "list_item",
        },
        {
          title: "下載專區",
          link: "/download",
          type: "list_item",
        },
        {
          title: "隨喜參讚",
          link: "/donation",
          type: "list_item",
        },
        {
          title: "聯絡我們",
          link: "/contact",
          type: "list_item",
        },
      ],
      menuGsap: null,
    };
  },
  methods: {
    OpenDropDown(index) {
      this.dropDownOpenIndex = this.dropDownOpenIndex == index ? -1 : index;
    },

    ResetDropDown() {
      this.dropDownOpenIndex = -1;
      const els = this.$refs.MainContent.querySelectorAll(
        "[data-dropdown-box]"
      );
      els.forEach((el) => {
        el.style.maxHeight = "0px";
      });
    },
  },
  watch: {
    menu_status() {
      if (this.menu_status) {
        this.menuGsap.open();
        document.querySelector("body").style.overflow = "hidden";
      } else {
        this.menuGsap.close();
        document.querySelector("body").style.overflow = "auto";
        this.ResetDropDown();
      }
    },
    dropDownOpenIndex() {
      const els = this.$refs.MainContent.querySelectorAll(
        "[data-dropdown-box]"
      );
      els.forEach((el, index) => {
        if (index == this.dropDownOpenIndex) {
          el.style.maxHeight = el.querySelector("div").clientHeight + "px";
        } else {
          el.style.maxHeight = "0px";
        }
      });
    },
  },
  mounted() {
    this.ResetDropDown();
    this.menuGsap = new MainMenu(this.$refs.MainContent);
    this.menuGsap.reset();
    // MenuListBox.value.style.maxHeight = "0px";
    window.addEventListener("resize", () => {
      if (this.menu_status != false) {
        this.$emit("menu-action");
      }
    });
  },
};
</script>
