<template>
  <section
    id="About"
    ref="MainContent"
    class="w-full px-5 mx-auto mb-20 lg:mb-40 max-w-screen-2xl 2xl:px-0"
    :class="section_ready ? '' : 'opacity-0'"
  >
    <div class="flex flex-wrap items-center w-full pb-20 md:pb-0">
      <div class="w-full md:w-1/2 md:pr-10">
        <header data-header-box class="relative pb-20 pt-28 md:pb-32">
          <h2
            data-header-title
            class="relative z-10 text-center md:text-left text-primary"
          >
            <span class="inline-block text-4xl font-bold sm:text-5xl">關</span>
            <span class="inline-block text-4xl font-bold sm:text-5xl">於</span>
            <span class="inline-block text-4xl font-bold sm:text-5xl">奉</span>
            <span class="inline-block text-4xl font-bold sm:text-5xl">天</span>
            <span class="inline-block text-4xl font-bold sm:text-5xl">宮</span>
          </h2>
          <div
            data-header-subtitle
            class="absolute z-0 w-full text-center transform -translate-x-1/2 md:w-auto md:text-left left-1/2 md:translate-x-0 md:left-44 md:bottom-auto bottom-24 md:-top-0"
          >
            <p
              class="inline-block text-5xl italic font-bold sm:text-7xl text-outlines sm:block"
            >
              <span class="inline-block opacity-50 font-garamond-pro">F</span>
              <span class="inline-block opacity-50 font-garamond-pro">E</span>
              <span class="inline-block opacity-50 font-garamond-pro">N</span>
              <span class="inline-block opacity-50 font-garamond-pro">G</span>
            </p>
            <p
              class="inline-block text-5xl italic font-bold sm:text-7xl text-outlines sm:block"
            >
              <span class="inline-block opacity-50 font-garamond-pro">T</span>
              <span class="inline-block opacity-50 font-garamond-pro">I</span>
              <span class="inline-block opacity-50 font-garamond-pro">A</span>
              <span class="inline-block opacity-50 font-garamond-pro">N</span>
            </p>
            <p
              class="block text-5xl italic font-bold sm:text-7xl text-outlines"
            >
              <span class="inline-block opacity-50 font-garamond-pro">P</span>
              <span class="inline-block opacity-50 font-garamond-pro">A</span>
              <span class="inline-block opacity-50 font-garamond-pro">L</span>
              <span class="inline-block opacity-50 font-garamond-pro">A</span>
              <span class="inline-block opacity-50 font-garamond-pro">C</span>
              <span class="inline-block opacity-50 font-garamond-pro">E</span>
            </p>
          </div>
        </header>
        <div data-section-content class="hidden md:block">
          <h4 class="mb-5 text-lg font-semibold text-secondary">
            {{ $GetColumn("home_about_title") }}
          </h4>
          <div
            class="mb-20 text-zinc-600"
            v-html="$GetColumn('home_about_content')"
          ></div>
          <div class="flex justify-end">
            <RouterLink
              to="/about"
              @mouseover.native="link_hover = true"
              @mouseleave.native="link_hover = false"
              class="flex items-center font-bold transition-all duration-500 w-52"
              :class="link_hover ? ' text-primary' : ' text-secondary'"
            >
              <i
                class="flex-1 block h-[1px] origin-right transform transition-transform duration-500 bg-current mr-3"
                :class="link_hover ? 'scale-x-75 ' : 'scale-x-100 '"
              ></i>
              了解更多
            </RouterLink>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div
          data-parallax-box
          class="w-full mb-10 overflow-hidden aspect-video md:mb-0"
        >
          <img
            :src="$ImageUrl($GetColumn('home_about_image'))"
            class="object-cover w-full h-[150%]"
          />
        </div>

        <div data-section-content class="block md:hidden">
          <h4 class="mb-5 text-lg font-semibold text-secondary">
            雷府千歲，歲次丁酉年晉陞『雷天帝王』之神格
          </h4>
          <div class="mb-10 text-zinc-600">
            <p>
              玉旨奉天宮雷王殿創建之歷史由來，歷經玉旨聖安府、玉旨奉天宮。乩文陳賜貴畢生心願創建玉旨奉天宮雷王殿，後世子孫完成其大願！
            </p>
          </div>
          <div class="flex justify-end">
            <RouterLink
              to="/about"
              @mouseover="link_hover = true"
              @mouseleave="link_hover = false"
              class="flex items-center font-bold transition-all duration-500 w-52"
              :class="link_hover ? ' text-primary' : ' text-secondary'"
            >
              <i
                class="flex-1 block h-[1px] origin-right transform transition-transform duration-500 bg-current mr-3"
                :class="link_hover ? 'scale-x-75 ' : 'scale-x-100 '"
              ></i>
              了解更多
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AboutSection } from "@/gsap/AboutSection.js";
export default {
  name: "HomeAbout",
  data() {
    return {
      link_hover: false,
      about_gsap: null,
      section_ready: false,
    };
  },
  methods: {
    SetGsap() {
      this.section_ready = true;
      this.about_gsap = new AboutSection(this.$refs.MainContent);
      this.about_gsap.reset();
      this.about_gsap.setup();
    },
  },
};
</script>
