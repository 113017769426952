<template>
  <div class="fixed top-0 left-0 z-[31] w-full">
    <div
      ref="AnnouncementMarquee"
      v-if="announcement_list.length > 0"
      class="relative z-20 w-full h-8 py-1 overflow-hidden text-sm font-medium text-center text-white bg-primary"
    >
      <p
        v-for="(item, item_index) in announcement_list"
        :key="`announcement_${item_index}`"
        class="absolute top-0 right-0 inline-flex items-center justify-end w-auto h-full overflow-hidden whitespace-nowrap"
      >
        <span v-if="item.link == ''">
          {{ item.content }}
        </span>
        <a
          v-else
          :href="item.link"
          target="_blank"
          :key="`announcement_${item_index}`"
          class="flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
        >
          {{ item.content }}
        </a>
      </p>
    </div>

    <div
      class="relative w-full"
      :class="scroll_active ? 'md:bg-white' : 'bg-transparent'"
    >
      <div
        class="relative flex items-center justify-between w-full mx-auto transition-all duration-300 md:px-5 max-w-screen-2xl 2xl:px-0"
        :class="scroll_active ? 'md:py-5 ' : 'md:py-10'"
      >
        <div class="hidden transition-all duration-300 md:block">
          <router-link
            to="/"
            class="relative block transition-all duration-300 opacity-80"
            :class="scroll_active ? 'w-32' : 'w-36'"
          >
            <img
              :src="$ImageUrl($GetColumn('header_logo'))"
              class="relative z-10 block w-full transition-all duration-1000"
            />
          </router-link>
        </div>
        <ol class="relative z-10 items-center hidden -mx-4 md:flex">
          <li
            v-for="(item, itemIndex) in menuList"
            :key="`menuItem${itemIndex}`"
          >
            <div
              @mouseenter="OpenDropDown(itemIndex)"
              @mouseleave="CloseDropDown(itemIndex)"
              v-if="item.type === 'dropdown'"
            >
              <router-link
                :to="item.link"
                :data-header-dropdown="itemIndex"
                class="px-3 py-2 text-sm font-thin transition-colors duration-200 rounded-md lg:px-4 lg:text-base hover:text-secondary"
                :class="scroll_active ? 'text-zinc-800 ' : 'text-zinc-800'"
              >
                {{ item.title }}
              </router-link>

              <div :ref="`DropDown_${itemIndex}`" class="fixed top-0 left-0">
                <ol class="px-4 py-2 bg-primary">
                  <li
                    v-for="(item, item_index) in menuList[itemIndex].list"
                    :key="`drop_down_${item_index}`"
                  >
                    <router-link
                      :to="item.link"
                      class="block w-full py-2 text-center text-white md:hover:text-secondary"
                      >{{ item.title }}</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div v-else-if="item.type === 'list_item'">
              <router-link
                :to="item.link"
                class="px-3 py-2 text-sm font-thin transition-colors duration-200 rounded-md lg:px-4 lg:text-base hover:text-secondary"
                :class="scroll_active ? 'text-zinc-800 ' : 'text-zinc-800'"
              >
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ol>

        <div
          class="relative z-10 flex items-center justify-between w-full md:hidden"
        >
          <router-link to="/" class="block pl-5">
            <img src="/img/logo_1.svg" class="w-28" />
          </router-link>

          <button
            @click="$emit('menu-action')"
            class="h-full px-5 aspect-square text-secondary"
          >
            <span v-show="!menu_status" class="text-2xl icon-menu"> </span>
            <span v-show="menu_status" class="text-2xl icon-close"> </span>
          </button>
        </div>
      </div>

      <!-- <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 flex-col hidden transition-all duration-300 md:flex"
        :class="scroll_active ? 'opacity-100 ' : 'opacity-0'"
      >
        <div
          class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black to-transparent"
        ></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { AnnouncementMarquee } from "@/gsap/AnnouncementMarquee";
import { HeaderDropDown } from "@/gsap/HeaderDropDown";
export default {
  name: "MainHeader",
  data() {
    return {
      menuList: [
        {
          title: "關於我們",
          link: "/about",
          type: "dropdown",
          list: [
            {
              title: "雷天帝王之沿革",
              link: "/about/history",
              type: "link",
            },
            {
              title: "神尊介紹",
              link: "/about/god",
              type: "link",
            },
            {
              title: "關於協會",
              link: "/about/association",
              type: "link",
            },
          ],
        },
        {
          title: "最新消息",
          link: "/news",
          type: "list_item",
        },
        {
          title: "影音專區",
          link: "/video",
          type: "list_item",
        },
        {
          title: "活動花絮",
          link: "/gallery",
          type: "list_item",
        },
        {
          title: "交通資訊",
          link: "/location",
          type: "list_item",
        },
        {
          title: "節日慶典",
          link: "/festival",
          type: "dropdown",
          list: [
            {
              title: "農曆新年",
              link: "/about/history",
              type: "link",
            },
            {
              title: "中元普渡",
              link: "/about/god",
              type: "link",
            },
            {
              title: "百年繞境",
              link: "/about/association",
              type: "link",
            },
          ],
        },
        {
          title: "隨喜參讚",
          link: "/donation",
          type: "list_item",
        },
        // {
        //   title: "聯絡我們",
        //   link: "/contact",
        //   type: "list_item",
        // },
      ],
      drop_down_list: [],
      dialog_timer: null,
      announcement_gsap: null,
      scroll_active: false, //滾動超過一定高度時，header背景變色
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("SetShopCartDialog", false);
    },
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
    SetGsap() {
      this.menuList.forEach((item, itemIndex) => {
        if (item.type == "dropdown") {
          let tmp_gsap = new HeaderDropDown(
            this.$refs[`DropDown_${itemIndex}`][0],
            itemIndex
          );
          tmp_gsap.reset();
          this.drop_down_list[itemIndex] = tmp_gsap;
        }
      });
    },
    OpenDropDown(index) {
      this.drop_down_list[index].open();
    },
    CloseDropDown(index) {
      this.drop_down_list[index].close();
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shop_cart_dialog;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog) {
        this.dialog_timer != null ? clearTimeout(this.dialog_timer) : "";
        this.dialog_timer = setTimeout(() => {
          this.CloseShopCartDialog();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.announcement_list.length > 0) {
      this.announcement_gsap = new AnnouncementMarquee(
        this.$refs.AnnouncementMarquee
      );
      this.announcement_gsap.reset();
      this.announcement_gsap.run();
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        this.scroll_active = true;
      } else {
        this.scroll_active = false;
      }
    });

    this.SetGsap();
  },
};
</script>
