<template>
  <li class="flex items-start w-full py-5 border-b border-zinc-100">
    <div class="w-1/4">
      <img :src="$ImageUrl(active_option_image)" />
    </div>
    <div class="flex-1 pl-5">
      <p class="mb-1 font-bold text-primary">
        {{ shop_cart_item.product_data.Title }}
      </p>
      <p class="text-xs font-medium" v-if="active_stock.ColorTitle != '無'">
        {{ shop_cart_item.product_data.Option1 }}：<span>{{
          active_stock.ColorTitle
        }}</span>
      </p>
      <p class="text-xs font-medium" v-if="active_stock.SizeTitle != '無'">
        {{ shop_cart_item.product_data.Option2 }}：<span>{{
          active_stock.SizeTitle
        }}</span>
      </p>
      <div class="inline-flex items-center mt-3 border border-zinc-300">
        <button
          @click="Remove(shop_cart_index, 1)"
          class="flex items-center justify-center w-6 h-6 transition-all duration-300 hover:md:opacity-50"
        >
          <i class="text-xs icon-minus"></i>
        </button>
        <input
          type="text"
          :value="shop_cart_item.amount"
          class="w-10 text-center"
        />

        <button
          @click="Add(shop_cart_index)"
          class="flex items-center justify-center w-6 h-6 transition-all duration-300 hover:md:opacity-50"
        >
          <i class="text-xs icon-plus"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-col items-end flex-shrink-0 text-right">
      <p class="mb-1 font-bold text-secondary">
        ${{ $MoneyFormat(total_price) }}
      </p>
      <button
        @click="Remove(shop_cart_index, shop_cart_item.amount)"
        class="flex items-center justify-center w-8 h-8 transition-all duration-300 border bg-opacity-20 text-primary bg-primary border-primary hover:md:bg-opacity-100 hover:md:text-white"
      >
        <i class="icon-close"></i>
      </button>
    </div>
  </li>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
import { GTM_RemoveShopCart } from "@/common/gtm_methods";
export default {
  name: "ShopCartProductCard",
  props: {
    shop_cart_item: {
      require: true,
      type: Object,
    },
    shop_cart_index: {
      require: true,
      type: Number,
    },
    is_member: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    Add(index) {
      // GTM_AddShopCart(this.shopcart[index], 1);
      const shop_cart_item = {
        product: this.shop_cart[index].product_data,
        options: this.shop_cart[index].active_option,
        amount: 1,
      };
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/AddShopCart", shop_cart_item);
      } else {
        this.$store.commit("shopcart_module/AddShopCart", shop_cart_item);
      }
    },
    Remove(index, count, type = "normal_product") {
      if (type == "normal_product") {
        GTM_RemoveShopCart(this.shop_cart[index], count);
      }
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      } else {
        this.$store.commit("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      }
    },
  },
  computed: {
    active_stock() {
      return this.shop_cart_item.product_data.Stock.filter((item) => {
        return (
          item.ColorID == this.shop_cart_item.active_option[0] &&
          item.SizeID == this.shop_cart_item.active_option[1]
        );
      })[0];
    },
    active_option_image() {
      const images = this.shop_cart_item.product_data.Picture;
      const full_match_image = images.filter(
        (item) =>
          item.ColorID == this.shop_cart_item.active_option[0] &&
          item.SizeID == this.shop_cart_item.active_option[1]
      );
      const end_match_image = images.filter(
        (item) => item.SizeID == this.shop_cart_item.active_option[1]
      );
      const start_match_image = images.filter(
        (item) => item.ColorID == this.shop_cart_item.active_option[0]
      );

      return full_match_image.length > 0
        ? full_match_image[0].Image
        : end_match_image.length > 0
        ? end_match_image[0].Image
        : start_match_image.length > 0
        ? start_match_image[0].Image
        : this.shop_cart_item.product_data.Image1;
    },
    total_price() {
      const sell_price =
        parseInt(this.active_stock.SellPrice) *
        parseInt(this.shop_cart_item.amount);
      const member_sell_price =
        parseInt(this.active_stock.MemberSellPrice) *
        parseInt(this.shop_cart_item.amount);
      return this.is_member ? member_sell_price : sell_price;
    },
    shop_cart() {
      return this.$store.state.shopcart_module.shopcart;
    },
  },
};
</script>