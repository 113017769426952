import gsap from "gsap";
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: "%",
    top: "%",
    rotation: "rad",
  },
});

export class Carousel {
  constructor(el) {
    this.carouselBox = el;
    this.carouselItems = el.querySelectorAll("[data-carousel-item]");
    this.timeline = null;

    this.activeIndex = 0;
  }

  reset() {
    gsap.set(this.carouselItems, {
      opacity: 0,
      webkitFilter: "blur(10px)",
    });
    gsap.set(this.carouselItems[0], {
      opacity: 1,
      webkitFilter: "blur(0px)",
    });
  }

  setup() {}

  destroy() {
    this.timeline != null ? this.timeline.kill() : "";
  }

  changeCarousel(index) {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    const otherCarousel = () => {
      let list = [];
      this.carouselItems.forEach((item, i) => {
        if (i != this.activeIndex) {
          list.push(item);
        }
      });
      return list;
    };

    this.activeIndex = index;

    this.timeline.set(
      this.carouselItems[this.activeIndex],
      {
        scale: 1.25,
      },
      "beforeShow"
    );

    this.timeline.to(
      otherCarousel(),
      {
        opacity: 0,
        scale: 1,
        webkitFilter: "blur(10px)",
        duration: 1,
      },
      "show"
    );
    this.timeline.to(
      this.carouselItems[this.activeIndex],
      {
        opacity: 1,
        webkitFilter: "blur(0px)",
        duration: 1,
      },
      "show"
    );
    this.timeline.to(
      this.carouselItems[this.activeIndex],
      {
        scale: 1,
        duration: 5,
        onComplete: () => {
          const nextIndex =
            this.activeIndex == this.carouselItems.length - 1
              ? 0
              : this.activeIndex + 1;
          this.changeCarousel(nextIndex);
        },
      },
      "show"
    );
  }
}
