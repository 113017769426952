import { gsap } from "@/gsap/GsapLoader";

export class AnnouncementMarquee {
  constructor(el) {
    this.el = el;
    this.announcement_items = el.querySelectorAll("p");

    this.timeline = null;
    this.timer = null;
    this.active_index = 0;
  }

  reset() {
    gsap.set(this.announcement_items, { x: "100%" });
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
  }

  setup() {
    if (this.announcement_items.length > 0) {
      this.reset();
      this.timer = setTimeout(() => {
        this.run();
      }, 5000);
    } else {
      gsap.set(this.announcement_items, { x: "100%" });
    }
  }

  run() {
    this.timer != null ? clearTimeout(this.timer) : "";
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    let next_index =
      this.active_index == this.announcement_items.length - 1
        ? 0
        : this.active_index + 1;

    const screen_width = window.innerWidth;
    const item_width = this.announcement_items[this.active_index].clientWidth;

    this.timeline.fromTo(
      this.announcement_items[this.active_index],
      {
        x: item_width,
      },
      {
        x: screen_width * -1,
        ease: "none",
        duration: 30,
        onComplete: () => {
          this.active_index = next_index;
          this.run();
        },
      }
    );
  }
}
