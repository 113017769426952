import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: "%",
    top: "%",
    rotation: "rad",
  },
});

export class GodSection {
  constructor(el) {
    this.mainEl = el;
    this.content = el.querySelectorAll("[data-section-content]");
    this.imageBox = el.querySelector("[data-image-box]");
    this.header = el.querySelector("[data-header-box]");
    this.headerTitle = this.header.querySelectorAll("[data-header-title]");

    this.imageCover = el.querySelectorAll("[data-image-cover]");
    this.imageCards = el.querySelectorAll("[data-image-card]");
    this.images = el.querySelectorAll("[data-image-card] img");
    this.contentCards = el.querySelectorAll("[data-content-card]");

    this.headerTitleEls = this.header.querySelectorAll(
      "[data-header-title] span"
    );
    this.headerSubTitle = this.header.querySelectorAll(
      "[data-header-subtitle]"
    );
    this.headerSubTitleEls = this.header.querySelectorAll(
      "[data-header-subtitle] span"
    );

    this.scrubImages = el.querySelectorAll("[data-scrub-image]");

    this.scrubTimeline = null;
    this.headerTimeline = null;
    this.carouselTimeline = null;

    window.addEventListener("resize", () => {
      this.resizeEvent();
    });

    this.activeIndex = 0;
  }

  resizeEvent() {
    this.reset();
    this.setup();
  }

  destroy() {
    window.removeEventListener("resize", () => {
      this.resizeEvent();
    });
  }

  reset() {
    this.activeIndex = 0;

    gsap.set(this.scrubImages[0], {
      x: "100%",
    });
    gsap.set(this.scrubImages[1], {
      x: "-100%",
    });

    gsap.set(this.imageCover, {
      opacity: 1,
    });
    gsap.set(this.imageCover[0], {
      opacity: 0,
    });
    gsap.set(this.imageCards, {
      x: "0%",
      opacity: 1,
    });
    gsap.set(this.contentCards, {
      opacity: 0,
      y: "100%",
    });
    gsap.set(this.contentCards[0], {
      opacity: 1,
      y: "0px",
    });

    gsap.set(this.headerSubTitleEls, {
      y: "200px",
    });
    gsap.set(this.headerSubTitle, {
      opacity: 0,
    });
    gsap.set(this.headerTitleEls, {
      y: "100px",
    });
    gsap.set(this.headerTitle, {
      opacity: 0,
    });
  }

  setup() {
    this.setScrubTimeline();
    this.setHeaderTimeline();
  }

  changeCarousel(action) {
    this.carouselTimeline != null ? this.carouselTimeline.kill() : "";
    this.carouselTimeline = gsap.timeline();

    this.carouselTimeline.to(
      this.imageCover,
      {
        opacity: 1,
        duration: 0.5,
      },
      "card-out"
    );

    this.carouselTimeline.to(
      this.contentCards,
      {
        opacity: 0,
        y: "30px",
        duration: 0.5,
      },
      "card-out"
    );

    if (action === "next") {
      this.activeIndex === this.images.length - 1
        ? (this.activeIndex = 0)
        : this.activeIndex++;
    } else {
      this.activeIndex === 0
        ? (this.activeIndex = this.images.length - 1)
        : this.activeIndex--;
    }

    this.carouselTimeline.set(
      this.contentCards,
      {
        y: "100%",
      },
      "card-before-move"
    );

    this.imageCards.forEach((card, cardIndex) => {
      if (cardIndex < this.activeIndex) {
        this.carouselTimeline.to(
          card,
          {
            opacity: 0,
            duration: 0.3,
          },
          "card-move"
        );
      }
      if (cardIndex >= this.activeIndex) {
        this.carouselTimeline.to(
          card,
          {
            opacity: 1,
            duration: 0.3,
          },
          "card-move"
        );
      }
    });

    if (window.innerWidth < 1024) {
      this.carouselTimeline.to(
        this.contentCards,
        {
          y: 0,
          x: this.activeIndex * -100 + "%",
          duration: 0.5,
        },
        "card-move"
      );
    }
    this.carouselTimeline.to(
      this.imageCards,
      {
        x: this.activeIndex * -100 + "%",
        duration: 0.6,
      },
      "card-move"
    );

    this.carouselTimeline.to(
      this.imageCover[this.activeIndex],
      {
        opacity: 0,
        duration: 0.3,
        delay: 0.6,
      },
      "card-move"
    );

    this.carouselTimeline.fromTo(
      this.contentCards[this.activeIndex],
      {
        opacity: 0,
        y: "30px",
      },
      {
        opacity: 1,
        y: "0px",
        duration: 0.6,
        delay: 0.4,
      },
      "card-move"
    );
  }

  setHeaderTimeline() {
    this.headerTimeline != null ? this.headerTimeline.kill() : "";
    this.headerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.mainEl,
        start: "top 60%",
        toggleActions: "restart play play reverse",
      },
    });

    this.headerTimeline.fromTo(
      this.headerSubTitle,
      {
        opacity: 0,
      },
      {
        opacity: 0.8,
        duration: 2,
      },
      "sub-title-in"
    );
    this.headerTimeline.fromTo(
      this.headerSubTitleEls,
      {
        y: "200px",
      },
      {
        y: "0px",
        stagger: 0.05,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.headerTitle,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.2,
        duration: 1,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.headerTitleEls,
      {
        y: "100px",
      },
      {
        y: "0px",
        stagger: 0.05,
        delay: 0.2,
        duration: 0.5,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.imageBox,
      {
        x: "30px",
        opacity: 0,
      },
      {
        x: "0px",
        opacity: 1,
        delay: 0.8,
        duration: 1,
      },
      "sub-title-in"
    );

    this.headerTimeline.fromTo(
      this.content,
      {
        y: "30px",
        opacity: 0,
      },
      {
        y: "0px",
        opacity: 1,
        delay: 1,
        duration: 0.5,
      },
      "sub-title-in"
    );
  }

  setScrubTimeline() {
    this.scrubTimeline != null ? this.scrubTimeline.kill() : "";
    this.scrubTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.mainEl,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    this.scrubTimeline.fromTo(
      this.scrubImages[0],
      {
        x: "100%",
      },
      {
        x: "-100%",
      },
      "same"
    );
    this.scrubTimeline.fromTo(
      this.scrubImages[1],
      {
        x: "-100%",
      },
      {
        x: "100%",
      },
      "same"
    );
  }
}
