<template>
  <aside
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 flex justify-end w-full left-full"
  >
    <div
      data-dialog-box
      class="h-full px-5 py-5 bg-white max-w-[90%] w-[500px] flex flex-col relative z-10"
    >
      <div class="flex items-center justify-between mb-5">
        <div>
          <p class="text-3xl font-bold uppercase text-primary">ShopCart</p>
          <p class="text-sm font-medium text-secondary">購物車</p>
        </div>
        <p class="text-sm font-bold">{{ shop_cart_count }} 項目</p>
      </div>
      <ol class="flex-1 w-full">
        <li
          v-for="(item, item_index) in shop_cart_data"
          :key="`shop_cart_item_${item_index}`"
          class="relative block w-full"
        >
          <ProductCard
            :shop_cart_item="item"
            :shop_cart_index="item_index"
            :is_member="is_member"
          />
        </li>
      </ol>

      <div class="pt-5 border-t border-zinc-100">
        <div v-if="shop_cart_count > 0">
          <p class="mb-5 text-sm text-zinc-500">運費與折扣將於結帳頁面計算</p>
          <button
            @click="
              $router.push('/checkout'),
                $store.commit('SetShopCartStatus', false)
            "
            class="block w-full py-3 font-medium text-center text-white transition-all duration-300 border bg-primary hover:md:bg-transparent hover:md:text-primary border-primary"
          >
            結帳
          </button>
        </div>
        <button
          v-else
          @click="
            $router.push('/collections'),
              $store.commit('SetShopCartStatus', false)
          "
          class="block w-full py-3 font-medium text-center text-white transition-all duration-300 border bg-primary hover:md:bg-transparent hover:md:text-primary border-primary"
        >
          前往購物
        </button>
      </div>
    </div>

    <div
      data-dialog-background
      @click="$store.commit('SetShopCartStatus', false)"
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-70"
    ></div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
import { ShopCart } from "@/gsap/ShopCart";
import { GTM_OpenShopCart } from "@/common/gtm_methods";
import ProductCard from "@/components/shopcart/ProductCard.vue";
export default {
  name: "ShopCartDrawer",
  components: {
    ProductCard,
  },
  data() {
    return {
      dialog_gsap: null,
    };
  },
  computed: {
    shop_cart_open() {
      return this.$store.state.shop_cart_open;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token") ? true : false;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
  },
  watch: {
    shop_cart_open() {
      if (this.shop_cart_open) {
        this.dialog_gsap.open();
        GTM_OpenShopCart(this.shop_cart_data);
      } else {
        this.dialog_gsap.close();
      }
    },
  },
  mounted() {
    this.dialog_gsap = new ShopCart(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
